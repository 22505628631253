import React from "react";

import HeroApp from "../components/Hero/HeroApp/HeroApp";
import Layout from "../components/Layout/layout";
import { SEO } from "../components/SEO/SEO";

const DownloadAllPlatforms = () => {
  return (
    <Layout>
      <div className="l-app">
        <HeroApp />
      </div>
    </Layout>
  );
};

export default DownloadAllPlatforms;

export const Head = () => (
  <SEO
    title={"Grupo Usieto | Descargas"}
    description={"Descarga la aplicación para iOs o Android"}
    robots={"noindex nofollow"}
  />
);
